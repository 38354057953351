import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  switchToGraph,
  switchToTree
} from 'actions/controls'

const viewMode = createReducer(
  {
    [switchToGraph]: () => 'graph',
    [switchToTree]: () => 'tree'
  },
  'graph'
)

export const reducer = combineReducers({
  viewMode
})
