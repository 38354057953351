import {combineReducers} from 'redux'

import {reducer as auth} from 'reducers/auth'
import {reducer as graph} from 'reducers/graph'
import {reducer as flashMessage} from 'reducers/flashMessage'
import {reducer as controls} from 'reducers/controls'

export default combineReducers({
  auth,
  graph,
  flashMessage,
  controls
})
