import wrapWithProvider from './wrapWithProvider'

import "./src/styles/global.css"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. ' +
    'Reload to display the latest version?'
  )
  if (answer) {
    window.location.reload()
  }
}

export const wrapRootElement = wrapWithProvider(true)
