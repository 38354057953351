import {all, fork} from 'redux-saga/effects'

import {watch as watchGraph} from './graph'

const rootSaga = function* () {
  yield all([
    fork(watchGraph)
  ])
}

export default rootSaga
