import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  fetchGraphRequest,
  fetchGraphSuccess,
  fetchGraphFailure
} from 'actions/graph'

const response = createReducer(
  {
    [fetchGraphSuccess]: (_state, {response}) => response,
    [fetchGraphFailure]: () => null
  },
  null
)

const isLoading = createReducer(
  {
    [fetchGraphRequest]: () => true,
    [fetchGraphSuccess]: () => false,
    [fetchGraphFailure]: () => false
  },
  false
)

const isError = createReducer(
  {
    [fetchGraphRequest]: () => null,
    [fetchGraphSuccess]: () => null,
    [fetchGraphFailure]: (_state, payload) => payload
  },
  null
)

export const reducer = combineReducers({
  response,
  isLoading,
  isError
})
