import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  showSuccessFlashMessage,
  showErrorFlashMessage,
  hideFlashMessage
} from 'actions/flashMessage'

// message types
const successMessageType = (message) => ({message, type: 'success'})
const errorMessageType = (message) => ({message, type: 'error'})

const flashMessageData = createReducer(
  {
    // basic
    [showSuccessFlashMessage]: (_state, payload) => successMessageType(payload),
    [showErrorFlashMessage]: (_state, payload) => errorMessageType(payload),
    // actions
    [hideFlashMessage]: () => ({}),
  },
  {}
)

export const reducer = combineReducers({
  data: flashMessageData
})
