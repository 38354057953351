import {combineReducers} from 'redux'
import {createReducer} from 'redux-act'
import {
  updateAuthUser
} from 'actions/auth'

const user = createReducer(
  {
    [updateAuthUser]: (_state, payload) => payload
  },
  null
)

const isLoaded = createReducer(
  {
    [updateAuthUser]: () => true
  },
  false
)

export const reducer = combineReducers({
  user,
  isLoaded
})
