import React from 'react'
import {Provider} from 'react-redux'
import {firebaseInterface} from 'lib/fapp'
import {updateAuthUser} from 'actions/auth'
import initializeStore from 'state/initializeStore'

const {
  firebaseApp,
  getAuth,
  onAuthStateChanged
} = firebaseInterface

const Wrapper = (isBrowser = false) => ({element}) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  const store = initializeStore()

  if (isBrowser && firebaseApp) {
    const auth = getAuth(firebaseApp)
    // if (process.env.NODE_ENV === 'development') {
    //   connectAuthEmulator(auth, "http://localhost:8000")
    // }
    onAuthStateChanged(auth, (user) => {
      if (user) {
        store.dispatch(updateAuthUser(user))
      } else {
        store.dispatch(updateAuthUser(null))
      }
    })

    store.dispatch(updateAuthUser(auth.currentUser))
  }

  return (
    <Provider store={store}>{element}</Provider>
  )
}

export default Wrapper
