let firebaseInterface = {}

if (typeof window !== 'undefined') {

  const gAuth = require('./gauth.json')
  const {initializeApp} = require('firebase/app')
  const {getStorage, ref, getDownloadURL} = require('firebase/storage')
  const {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    browserLocalPersistence,
    signInWithCredential,
    signOut,
    signInWithPopup,
    setPersistence,
    connectAuthEmulator
  } = require('firebase/auth')

  const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
  const gCredentials = gAuth[activeEnv] || {}

  const firebaseConfig = {
    apiKey: gCredentials['apiKey'],
    authDomain: gCredentials['domain'],
    projectId: 'rw-orgmap',
    storageBucket: 'rw-orgmap-bqvis',
    messagingSenderId: '400426576597',
    appId: '1:400426576597:web:6a1ad94c085748294a505c'
  }

  const firebaseApp = initializeApp(firebaseConfig)

  firebaseInterface = {
    firebaseApp,
    getAuth,
    setPersistence,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    browserLocalPersistence,
    signInWithCredential,
    connectAuthEmulator,
    signOut,
    getStorage,
    ref,
    getDownloadURL
  }
}

export {
  firebaseInterface
}
