import {put, call, select, takeLatest} from 'redux-saga/effects'
import {makeRequest} from 'httpApi/instance'
import {firebaseInterface} from 'lib/fapp'
import {getUser} from 'selectors/auth'
import {
  fetchGraphRequest,
  fetchGraphSuccess,
  fetchGraphFailure
} from 'actions/graph'

const getFileRef = () => {
  if (firebaseInterface.getStorage && firebaseInterface.ref) {
    const storage = firebaseInterface.getStorage()
    return {
      ref: firebaseInterface.ref(storage, 'gs://rw-orgmap-bqvis/results.json'),
      getDownloadURL: firebaseInterface.getDownloadURL
    }
  }
  return {}
}

export const fetchGraph = function* () {
  try {
    const user = yield select(getUser)

    if (user) {
      const file = getFileRef()

      if (file.ref && file.getDownloadURL) {
        const url = yield call(file.getDownloadURL, file.ref)

        const response = yield call(makeRequest, url)
        yield put(fetchGraphSuccess({response}))
      } else {
        yield put(fetchGraphFailure({error: 'no firebase'}))
      }
    } else {
      yield put(fetchGraphFailure({error: 'no user'}))
    }
  } catch (error) {
    yield put(fetchGraphFailure(error))
  }
}

export const watch = function* () {
  yield takeLatest(fetchGraphRequest, fetchGraph)
}
